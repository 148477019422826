import { theme, useResponsive } from "@product/scmp-sdk";
import chunk from "lodash/chunk";
import { type FunctionComponent, useState } from "react";
import { graphql, useFragment } from "react-relay";

import { BaseLinkContextProvider } from "scmp-app/components/common/base-link/context";
import { tracking } from "scmp-app/data";
import { useCurrentPageType } from "scmp-app/lib/router/hooks";
import type { discoveryWidgetPostMagazineQueueQuery$key } from "scmp-app/queries/__generated__/discoveryWidgetPostMagazineQueueQuery.graphql";

import Logo from "./logo.svg";
import {
  Container,
  FirstItem,
  MoreLink,
  Publication,
  RestItems,
  StyledContentItemPostMagazineDiscovery,
  StyledSwiper,
  StyledSwiperSlide,
  SwiperContainer,
  TabletItems,
  Title,
} from "./styles";

type Props = {
  className?: string;
  reference: discoveryWidgetPostMagazineQueueQuery$key;
};

export const PostMagazineDiscoveryWidget: FunctionComponent<Props> = ({ className, reference }) => {
  const data = useFragment(
    graphql`
      fragment discoveryWidgetPostMagazineQueueQuery on Query {
        postMagazineDiscoveryQueue: queue(filter: { name: "section_top_71" }) {
          items(first: 6) {
            edges {
              node {
                ... on Article {
                  entityId
                  ...postMagazineDiscoveryItemContent
                }
              }
            }
          }
        }
      }
    `,
    reference,
  );

  const postMagItems = data?.postMagazineDiscoveryQueue?.items?.edges ?? [];
  const isTabletUp = useResponsive(theme.breakpoints.up("tablet"), false);
  const currentPageType = useCurrentPageType();
  const [isInit, setIsInit] = useState(false);

  if (postMagItems?.length === 0) return null;

  const items = isTabletUp ? postMagItems.slice(0, 5) : postMagItems;
  const chunkItems = chunk(items, 2);
  const [firstItem, ...restItems] = items;

  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: tracking.module.PostMagazine,
        pgtype: currentPageType,
      }}
    >
      <Container $isInit={isInit} className={className}>
        <Title>HIGHLIGHTS FROM</Title>
        <Logo />
        <TabletItems>
          <FirstItem>
            <StyledContentItemPostMagazineDiscovery
              key={firstItem.node.entityId}
              reference={firstItem.node}
              withSummary
            />
          </FirstItem>
          <RestItems>
            {restItems.map(({ node }) => (
              <StyledContentItemPostMagazineDiscovery key={node.entityId} reference={node} />
            ))}
          </RestItems>
        </TabletItems>

        <SwiperContainer>
          <StyledSwiper
            breakpoints={{
              768: {
                direction: "vertical",
                slidesPerView: 5,
                spaceBetween: 24,
              },
            }}
            cssMode={false}
            navigation={false}
            onInit={swiper => {
              if (swiper) {
                setIsInit(true);
              }
            }}
            pagination={{
              clickable: true,
            }}
            slidesPerView={1}
            spaceBetween={16}
          >
            {chunkItems.map((item, index) => (
              <StyledSwiperSlide key={index}>
                {item.map(({ node }) => (
                  <StyledContentItemPostMagazineDiscovery key={node.entityId} reference={node} />
                ))}
              </StyledSwiperSlide>
            ))}
          </StyledSwiper>
        </SwiperContainer>
        <MoreLink pathname="/postmag">MORE IN POSTMAG</MoreLink>
        <Publication>{`Content from SCMP's Specialist Publications`}</Publication>
      </Container>
    </BaseLinkContextProvider>
  );
};

PostMagazineDiscoveryWidget.displayName = "PostMagazineDiscoveryWidget";
